.App {
	width: 100vw;
	height: 100vh;
	background-position: 32% 49%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url('../public/hare.png');
	text-align: center;
}

@media (max-width: 600px) {
	.App {
		background-position: 26% 100%;
		background-size: cover;
	}
}

@keyframes scrollSigil {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: 0 -100%;
	}
}

#pageWrapper {
	width: calc(100dvw);
	height: calc(100dvh);
	display: flex;
	flex-direction: column;
}

#sigil {
	height: 90%;
	padding-top: calc(5dvh);
	flex-grow: 1;
	position: relative;
	-webkit-mask-image: url('../public/mark_of_the_loyal_sigil.png');
	mask-image: url('../public/mark_of_the_loyal_sigil.png');
	mask-size: contain;
	mask-repeat: no-repeat;
	mask-position: 50% 50%;
}

.sigil-bg {
	background-image: url('../public/paint_pattern.png');
	background-repeat: repeat;
	background-size: 25%;
	animation: scrollSigil 25s linear infinite;
}

#footer {
	height: calc(15dvh);
}

#footer svg {
	height: calc(9dvh);
	width: calc(9dvh);
	fill: #8a0000;
}